import { Component, OnInit, ViewChild, ElementRef, Output, EventEmitter, Input, SimpleChanges } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-intro',
	templateUrl: './intro.component.html',
	styleUrls: ['./intro.component.scss']
})
export class IntroComponent implements OnInit {

	@Input() play: boolean;
	@Output() finished = new EventEmitter<boolean>();
	preloader = false;

	public videoSource = "5e54c59237a18017ac47092d8770fe76";

	constructor(private router: Router) { }

	ngOnInit() {
		this.preloader = true;

		if(screen.availHeight > screen.availWidth){
			console.log("Please use Portrait!");
			this.videoSource = "ad2b51d6caa86b6f5cde592221775bb9"; // Portrait (vertical) video 
		} else {
			console.log("Please use Landscape!");
			this.videoSource = "5e54c59237a18017ac47092d8770fe76"; // Landscape video 
		}
	}

	ngAfterViewInit() {
		//this.preloader = false;
	}

	onFinishHandler(e) {
		console.log('Finish handler: ', e);
		this.finished.emit(true);
	}
	
	onLoadedHandler(e) {
		console.log('Loaded handler: ', e);
		this.preloader = false;
	}

}
