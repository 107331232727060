import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from './modules/material.module';

// import { CloudflareStreamModule } from "@cloudflare/stream-angular";

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { ShareDataService } from "./service/shareData.service";

// Dialogs

import { TermsComponent } from './components/dialogs/general/terms/terms.component';
import { AboutComponent } from './components/dialogs/general/about/about.component';

import { ResultsComponent } from './components/dialogs/training/results/results.component';
import { SectionDialogComponent } from './components/dialogs/training/section-dialog/section-dialog.component';
import { TrainingDialogComponent } from './components/dialogs/training/training-dialog/training-dialog.component';

import { HeaderComponent } from './components/layout/header/header.component';
import { FooterComponent } from './components/layout/footer/footer.component';



// Authentication
import { AuthComponent } from './components/auth/auth.component';

import { AuthService } from './service/auth/auth.service';
import { AuthGuard } from './service/auth/auth.guard';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ErrorInterceptor } from './helpers/error.interceptor';

import { PagesComponent } from './components/layout/pages-layout/pages.component';
import { TrainingComponent } from './components/layout/training-layout/training.component';

import { AuthStoreService } from './service/store/auth-store.service';

import { FullscreenButtonComponent } from './components/layout/header/fullscreen-button/fullscreen-button.component';

import { SharedModule } from './components/shared/shared.module';



@NgModule({
	declarations: [
		AppComponent,
		AuthComponent,
		HeaderComponent,
		ResultsComponent, SectionDialogComponent, TrainingDialogComponent,
		FooterComponent,
		TermsComponent,
		AboutComponent,
		PagesComponent,
		TrainingComponent,
		FullscreenButtonComponent
	],
	imports: [
		BrowserModule,
		AppRoutingModule,
		BrowserAnimationsModule,
		HttpClientModule,
		MaterialModule,
		//CloudflareStreamModule,
		SharedModule
	],
	exports: [],
	entryComponents: [
		TermsComponent,
		ResultsComponent,
		AboutComponent
	],
	providers: [
		ShareDataService,
		AuthService, AuthGuard, AuthStoreService,

		{ provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
		{ provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
