import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { AuthComponent } from './components/auth/auth.component';
import { PagesComponent } from './components/layout/pages-layout/pages.component';
import { TrainingComponent } from './components/layout/training-layout/training.component';
import { AuthGuard } from './service/auth/auth.guard';

const routes: Routes = [
	{
        path: '',
        redirectTo: 'orientation',
        pathMatch: 'full',
	},
    {
        path: '',
        children: [ 
		{
            path: 'auth',
			component: AuthComponent,
        }]
	},
    {
        path: '',
        children: [ 
		{
            path: 'auth/:guid',
			component: AuthComponent,
        }]
	},		
	{ // Demo pages component
        path: '',
        component: TrainingComponent,
        children: [
			{
            	path: 'orientation',
				loadChildren: () => import('./components/trainings/training.module').then(m => m.TrainingModule) 
			}
		],
		canActivate: [AuthGuard] 
    },
	{ // Demo pages component
        path: '',
        component: PagesComponent,
        children: [{
            path: 'page',
			loadChildren: () => import('./components/pages/pages.module').then(m => m.PagesModule) 
		}],
    }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
