import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { AuthService } from '../../service/auth/auth.service';
import { AuthResponse } from 'src/app/models/user';
import { AuthStoreService } from 'src/app/service/store/auth-store.service';

@Component({
	selector: 'app-auth',
	templateUrl: './auth.component.html',
	styleUrls: ['./auth.component.scss']
  })
  export class AuthComponent implements OnInit {

	
	public guid: string = '';
	public isLoggedIn = false;
	public playIntro = true;

	constructor (
		private router: Router,
		private route: ActivatedRoute,
		private service: AuthService,
		private store: AuthStoreService
	) {

	}
	
	ngOnInit() {
		this._guidAuth();
	}

	_guidAuth() {
		this.route.params.subscribe(
			params => {
				if (params['guid']) {
					this._authenticate(params['guid']);
				} else {
					// redirect to tests if already logged in
					if (this.store.auth) {
						this.router.navigate(['/orientation/trainings']);
					} else {
						let msg = '';
						this.router.navigate(['/page/info', msg]);
					}
				}
			},
			error => {
				console.info('Vacancy URL params error: ', error);
			}
		);
	}

	private _authenticate(guid: string) {
		this.service.clearAuth();

		this.service.auth(guid).subscribe(
			response => {
				this._authHandler(response);
			},
			error => {
				console.log('[AuthComponent] Auth error: ', error);
			}
		);
	}

	private _authHandler(auth: AuthResponse) {
		//this.router.navigate([`/orientation/trainings`]);

		console.log('[AuthComponent] Applicant Auth response: ', auth);
		//alert('You are logged in now');

		this.isLoggedIn = true;

		if(!this.playIntro) {
			this.router.navigate(['/orientation/trainings']);
		}
	}

	onIntroFinished() {
		if(this.isLoggedIn) {
			//this.playIntro = false;
			this.router.navigate(['/orientation/trainings']);
		} else {
			alert('Login issue, please contact your JDM Agent.')
		}
	}

	ngOnDestroy() {

	}
}
