import { Component, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'test-results',
  templateUrl: './results.component.html',
  styleUrls: ['./results.component.scss']
})

export class ResultsComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private termsDialogRef: MatDialogRef<ResultsComponent>) { }

  ngOnInit() {
	  console.log('Test Result Data: ', this.data)
  }

  resetTest(){
    this.termsDialogRef.close('reset');
  }
  
  okClick(){
    this.termsDialogRef.close('ok');
  }
}
