import { Component, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
	selector: 'training-dialog',
	templateUrl: './training-dialog.component.html',
	styleUrls: ['./training-dialog.component.scss']
})

export class TrainingDialogComponent implements OnInit {

	constructor(@Inject(MAT_DIALOG_DATA) public data: any, private termsDialogRef: MatDialogRef<TrainingDialogComponent>) { }

	ngOnInit() {
		console.log('Test Result Data: ', this.data)
	}

	resetTest() {
		this.termsDialogRef.close('reset');
	}

	okClick() {
		this.termsDialogRef.close('ok');
	}

	public percent = () => {
		var value = 0;
		switch (this.data) {
			case 'BASIC':
				value = null
				break;
			case 'SAFETY':
				value = 30
				break;
			case 'MAINTENANCE':
				value = 45
				break;
			case 'HOS':
				value = 60
				break;
			case 'ACCOUNTING':
				value = 80
				break;
			case 'DISPATCH':
				value = 100
				break;
			default:
				break;
		}
		return value
	}
}
