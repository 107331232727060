import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthStoreService } from '../service/store/auth-store.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

	constructor(private authStore: AuthStoreService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		
		let currentUser = this.authStore.auth;

		// add authorization header with jwt token if available
		console.info('JwtInterceptor User', currentUser);

        if (currentUser && currentUser.token && request.url.search('mandril') < 0 && request.url.search('auth') < 0) {
			request = request.clone({
                setHeaders: { 
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        return next.handle(request);
    }
}