import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthResponse } from '../../models/user';

import { AuthStoreService } from '../store/auth-store.service';

import * as G from '../../config/globals';

@Injectable()
export class AuthService {

	constructor( 
		private http: HttpClient, 
		private authStore: AuthStoreService
		) { 

	}

	auth(guid: string):Observable<AuthResponse> {
		
		const httpOptions = {
			headers: new HttpHeaders({
			  'Content-Type':  'application/json',
			})
		};

        var data = `"${guid}"`;

        return this.http.post<AuthResponse>(`${G.apiUrl}/employees/authenticate`, data, httpOptions)
				.pipe(
					map(
						response => {
							if (response.token) {
								this._authHandler(response);
							}
							return response;
						}
					)
				);
		}

	private _authHandler(auth: AuthResponse) {
		localStorage.setItem(G.LocalVariable.Employee, JSON.stringify(auth));
		this.authStore.auth = auth;
	}

	logout() {
        // remove user from local storage to log user out
		localStorage.removeItem(G.LocalVariable.Employee);
		this.authStore.auth = null;
	}

	clearAuth() {
		localStorage.removeItem(G.LocalVariable.Employee);
		this.authStore.auth = null;
	}

	get isLoggedIn(): boolean { return (!this.authStore.auth) ? false : true; }

}
