<div class="jdm-text-blue">
	<div mat-dialog-content>
		<div class="text text-justify align-center jdm-text-blue">
			<!-- <p>Total Questions: {{data.numberOfQuestions}}</p>
		<p>Corect answers: {{data.correctAnswers}}</p>
		<p>Score: {{data.correctAnswers/data.numberOfQuestions * 100 | number:'1.0-0'}} %</p>
		<hr> -->

			<P *ngIf="data.testPassed">
				Congratulations! You successfully finished this section of the training. You can now continue to the
				next
				lesson.
			</P>
			<p *ngIf="!data.testPassed">
				Please watch this video again, then take the test again. You need to pass this test in order to continue
				to
				the next lesson.
			</p>
			<div class="jdm-hr"></div>
		</div>
	</div>
	<div mat-dialog-actions>
		<button *ngIf="data.testPassed" mat-button class="pull-right" mat-dialog-close (click)="okClick()" tabindex="-1">Continue</button>

		<button *ngIf="!data.testPassed" mat-button class="pull-right" mat-dialog-close (click)="resetTest()" tabindex="-1">Watch again</button>
	</div>
</div>