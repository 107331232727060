// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	 
	// bridgeUrl: 'http://bridge.local/api',
 //apiUrl: 'https://jdm-employeeapi-int.azurewebsites.net/api',   //Ovo sa, ja iskomentarisao
  //apiUrl: 'https://localhost:44320/api',
  apiUrl: 'https://emplyeesapi.azurewebsites.net/api',
  

//	bridgeUrl: 'https://bridge-int.jdmexpedite.com/api',   //ovo sam ja iskometarisao
	//apiUrl: 'https://jdm-employeeapi-prod.azurewebsites.net/api',
	bridgeUrl: 'https://bridge.jdmexpedite.com/api',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
