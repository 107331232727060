import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

import { AuthResponse } from '../../models/user';

import * as G from '../../config/globals';

@Injectable({providedIn: 'root'})
export class AuthStoreService {
	private readonly _auth = new BehaviorSubject<AuthResponse>(null);

	// Expose the observable$ part of the _todos subject (read only stream)
	readonly auth$ = this._auth.asObservable();

	constructor() {
		this.auth = JSON.parse(localStorage.getItem(G.LocalVariable.Employee));		//console.log('[AuthStoreService] localStorage: ', JSON.parse(localStorage.getItem(G.LocalVariable)));
	}

	// the getter will return the last value emitted in _todos subject
	get auth(): AuthResponse {
		return this._auth.getValue();
	}

	// assigning a value to this.todos will push it onto the observable 
	// and down to all of its subsribers (ex: this.todos = [])
	set auth(val: AuthResponse) {
		this._auth.next(val);
	}

	get isLoggedIn(): boolean { return (!this.auth) ? false : true; }
}