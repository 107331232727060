import {DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';

declare var Stream: any;

@Component({
	selector: 'cloudflare-custom',
	templateUrl: './cloudflare-custom.component.html',
	styleUrls: ['./cloudflare-custom.component.scss'],

})
export class CloudflareCustomComponent implements OnInit {

	@Input() src: string;
	@Input() landscape: boolean;

	@Output() ended = new EventEmitter<boolean>();
	@Output() loaded = new EventEmitter<boolean>();

	public videoSrc: SafeUrl;
	public player = null;
	public playing: boolean = false;
	public loadeddata: boolean = false;
	public loadstart: boolean = false;
	public canplay: boolean = false;

	public ratio = 9/16;

	constructor(private sanitizer: DomSanitizer) { }

	ngOnChanges(changes: SimpleChanges) {
		if(('src' in changes)) {
			if(this.player) {
				this._setupPlayer();
			}
		}
	}



	_setupPlayer() {
		this.player = Stream(document.getElementById('stream-player'));
		
		console.log('[ngOnChanges] src:', this.src);

		this.videoSrc = this.sanitizer.bypassSecurityTrustResourceUrl('https://iframe.videodelivery.net/' + this.src + '?autoplay=true&controls=false');
		
		this.player.controls = false;
		this.player.autoplay = true;

		this._setOrientation();
	}

	_setOrientation() {
		if(this.landscape) {
			this.ratio = 9/16;
		} else {
			if(screen.availHeight > screen.availWidth){
				console.log("Please use Portrait!");
				this.ratio = 16/9;
			} else {
				console.log("Please use Landscape!");
				this.ratio = 9/16;
			}
		}
	}

	ngOnInit(): void {
		this._setupPlayer();

		setTimeout(()=>{ 
			if(!this.canplay) {
				this._setupPlayer();
			}
		}, 1000)

		this.player.addEventListener('ended', () => {
			console.log('Ended!')
			this.ended.emit(true);
		})

		this.player.addEventListener('play', () => {
			console.log('Play!')
			this.playing = true;
		})

		this.player.addEventListener('pause', () => {
			console.log('Pause!')
			this.playing = false;
		})

		this.player.addEventListener('loadstart', () => {
			console.log('loadstart!')
			this.loadstart = true;
			this.loaded.emit(true);
		})

		this.player.addEventListener('loadeddata', () => {
			console.log('loadeddata!')
			this.loadeddata = true;
			this.loaded.emit(true);
		})

		this.player.addEventListener('canplay', () => {
			console.log('canplay!')
			this.canplay = true;
			this.loaded.emit(true);
		})

		this.player.addEventListener('playing', () => {
			console.log('playing!')
			this.playing = true;
		})
	}

	next() {
		this.ended.emit(true);
	}

	onPlay() {
		this.player.play();
	}

	onPause() {
		this.player.pause();
	}
}
