import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';

import { AuthStoreService } from '../store/auth-store.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {

    constructor(
        private router: Router,
        private authStore: AuthStoreService
    ) {}

	canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
		const currentUser = this.authStore.auth;

		if (currentUser) {
			// logged in so return true
			console.log('Gueard logged in chacked.:', currentUser)
			return true;
		}

		// not logged in so redirect to login page with the return url
        this.router.navigate(['/page/info'], { queryParams: { returnUrl: state.url }});
        return false;
	}
}
