<div class="jdm-text-orange">
	<h2 mat-dialog-title>Terms and Conditions</h2>

	<div mat-dialog-content>
		<p>
			By using this application, you certify that you have read and reviewed
			this Agreement and that you agree to comply with its terms. Before you
			continue using our application, we advise you to read our <a href="#" class="jdm-text-orange">privacy policy</a>  regarding
			our user data collection. It will help you better understand our practices.
		</p>
	</div>
	<div class="mt-4">
		<p>Please check and accept the terms and conditions.</p>
		<mat-checkbox [(ngModel)]="accepted">I have read, understood, and agreed with your terms and conditions.
		</mat-checkbox>
	</div>

	<div mat-dialog-actions>
		<button mat-button (click)="userAcceptedTerms()" [disabled]="!accepted" class="ms-4">Accept and Continue</button>
	</div>
</div>