<div class="intro-wrapper">
	<div class="container-fluid video">
		<!-- <video #videoPlayer width="100%" (ended)="onFinishHandler($event)" (click)="onClickHandler()"  muted autoplay oncanplay="this.play()" onloadedmetadata="this.muted = true">
			<source src="{{videoSource}}" type="video/mp4" />
		</video> -->

		<!-- <cloudflare-stream
			[controls]="true"
			[autoplay]="true"
			src="{{videoSource}}"
			(ended) = "onFinishHandler($event)"
			(error) = "onVideoEventHandler($event)"
			(seeked) = "onVideoEventHandler($event)"
		></cloudflare-stream> -->

		<ng-container *ngIf="preloader">
			<div class="row vh-100">
				<div class="col-12 my-auto">
					<div class="text-center">
						<div class="spinner-border jdm-text-blue" role="status" style="width: 10rem; height: 10rem; ">
						  <span class="visually-hidden">Loading...</span>
						</div>
					  </div>
				</div>
			</div>
		</ng-container>

		<ng-container>
			<cloudflare-custom src="{{videoSource}}" (ended)="onFinishHandler($event)" (loaded)="onLoadedHandler($event)"></cloudflare-custom>
		</ng-container>

		<!-- <div id="preloader">
			<div id="loader"></div>
		</div> -->
	</div>
</div>