<div #playerWrapper class="player-wrapper">
	<iframe
	[src]="videoSrc"
	style="border: none;"
	[width]="playerWrapper.offsetWidth"
	[height]="playerWrapper.offsetWidth*ratio"
	allow="accelerometer; gyroscope; autoplay; encrypted-media; picture-in-picture;"
	allowfullscreen="true"
	id="stream-player"
  ></iframe>
  <div class="video-controls" *ngIf="canplay && !playing">
	  <button mat-icon-button (click)="onPlay()" *ngIf="!playing">
		  <mat-icon>play_arrow</mat-icon>
	  </button>
	  <!-- <button mat-icon-button (click)="onPause()" *ngIf="playing">
		  <mat-icon>pause</mat-icon>
	  </button> -->
  </div>
</div>
