import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Training, SubSection } from "../models/training";
import { User } from '../models/user';
import { SubSectionMerged } from '../models/trainingMerged';
import { Certificate } from '../models/certificate';

@Injectable()
export class ShareDataService {

	private traningItemSource = new BehaviorSubject<Training>(null);
	currenTrainingItem = this.traningItemSource.asObservable();

	private itemSource = new BehaviorSubject<SubSectionMerged>(null);
	currentItem = this.itemSource.asObservable();

	private YTAPISource = new BehaviorSubject<boolean>(null);
	youtubeAPILoaded = this.YTAPISource.asObservable();

	
	private certificatesSubject = new BehaviorSubject<Certificate[]>(null);
	certificates = this.certificatesSubject.asObservable();


	constructor() { }

	setTrainingItem(item: Training) {
		this.traningItemSource.next(item)
	}

	setItem(item: SubSectionMerged) {
		this.itemSource.next(item)
	}

	setYTAPILoaded(v: boolean) {
		this.YTAPISource.next(v);
	}

	private userSource = new BehaviorSubject<User>(null);
	public user = this.userSource.asObservable();

	setUser(user: User) {
		this.userSource.next(user);
	}

	setCertificates(cert: Certificate[]) {
		this.certificatesSubject.next(cert);
	}
}