import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CloudflareCustomComponent } from '../shared/cloudflare-custom/cloudflare-custom.component';
import { IntroComponent } from './intro/intro.component';
import { MaterialModule } from 'src/app/modules/material.module';

@NgModule({
	imports: [
		CommonModule,
		MaterialModule
	],
	declarations: [
		IntroComponent,
		CloudflareCustomComponent
	],
	providers: [],
	exports: [
		IntroComponent,
		CloudflareCustomComponent
	]
})

export class SharedModule { }
