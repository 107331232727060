<section class="header pt-4 px-4 desktop">
	<div class="container-fluid">
		<div class="row">
			<div class="col-4 pt-4">
				<strong class="jdm-text-blue">DRIVER ORIENTATION</strong>
				<fullscreen-button  [size]="'large'"></fullscreen-button>
			</div>
			<div class="col-4 text-center pt-4">
				<img width="35" src="assets/Images/shared/steering-wheel.png" (click)="openAboutDialog()">
				<span class="username ms-3">{{employee.name}}</span>
			</div>
			<div class="col-4 text-end">
				<img class="logo" src="assets/Images/jdm-logo.png">
			</div>
		</div>
	</div>
</section>
<section class="header pt-4 px-3 mobile">
	<div class="container-fluid">
		<div class="row">
			<div class="col-4">
				<img class="logo" width="75" src="assets/Images/jdm-logo.png">
			</div>
			<div class="col-8 text-end">
				<button mat-icon-button (click)="goToTrainings()" class="jdm-text-blue home-btn">
					<mat-icon aria-label="Example icon-button with a heart icon">home</mat-icon>
				</button>
				<strong class="jdm-text-blue">DRIVER ORIENTATION</strong>
				<fullscreen-button></fullscreen-button>
			</div>
		</div>
		<div class="user-mobile">
			<img width="25" src="assets/Images/shared/steering-wheel.png" (click)="openAboutDialog()">
			<span class="username ms-3">{{employee.name}}</span>
		</div>
	</div>
</section>