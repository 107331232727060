import { Component, OnInit, Input } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';

@Component({
  selector: 'training-section-dialog',
  templateUrl: './section-dialog.component.html',
  styleUrls: ['./section-dialog.component.scss']
})

export class SectionDialogComponent implements OnInit {

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private termsDialogRef: MatDialogRef<SectionDialogComponent>) { }

  ngOnInit() {
	  console.log('Test Result Data: ', this.data)
  }

  resetTest(){
    this.termsDialogRef.close('reset');
  }
  
  okClick(){
    this.termsDialogRef.close('ok');
  }
}
