import { Component, Inject, OnInit } from '@angular/core';
import { AboutComponent } from '../../dialogs/general/about/about.component';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'
import { Router } from '@angular/router';
import { User } from '../../../models/user';
import { AuthStoreService } from 'src/app/service/store/auth-store.service';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

	aboutDialogRef: MatDialogRef<AboutComponent>;
	employee: User;


	constructor(
		
		private dialog: MatDialog, private router: Router,
		private authStore: AuthStoreService
	) { }

	ngOnInit() {
		this.authStore.auth$.subscribe(
			response => {
				this.employee = response.employee;
				console.log('[HeaderComponent] Auth Response: ', response);
			}
		);

		
	}


	openAboutDialog() {
		this.aboutDialogRef = this.dialog.open(AboutComponent, {
			maxWidth: '70vw',
			minHeight: '70vh',
			panelClass: 'jdm-dialog-container'
		});
	}



	device = "desktop";

	ngAfterViewInit() {
		var ua = navigator.userAgent;

		if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/i.test(ua)) {
			this.device = "mobile";
		} else if (/Chrome/i.test(ua)) {
			this.device = "chrome";
		} else {
			this.device = "desktop";
		}
	}

	goToTrainings() {
		this.router.navigate(['/orientation/trainings']);
	}

}
