<div class="jdm-text-green">
	<div mat-dialog-content>
		<div class="text text-justify align-center">
			<P>
				Congratulations! You successfully finished this training. 
			</P>
		</div>
		<div *ngIf="percent()"class="text text-justify align-center">
			<P>
				You are now on the {{percent()}}%. Please continue to the next training.
			</P>
			<div class="progress" style="height: 20px;">
				<div class="progress-bar" role="progressbar" [ngStyle]="{'width.%': percent()}" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		</div>
	</div>
	<div mat-dialog-actions>
		<button mat-button class="pull-right" mat-dialog-close (click)="okClick()" tabindex="-1">Continue</button>
	</div>
</div>