<div class="jdm-text-orange">
	<h1 mat-dialog-title>About JDM Driver Orientation</h1>

	<div mat-dialog-content>
		<div class="text text-justify">
			<p>We have developed an online orientation to save your time. It includes videos and questions to help you
				learn more about the company, policies, and procedures.</p>
		</div>
	</div>

	<div mat-dialog-actions>
		<button mat-button class="pull-right" mat-dialog-close>Ok</button>
	</div>
</div>