<div>
	<div mat-dialog-content>
		<div class="text text-justify align-center">
			<!-- <p>Total Questions: {{data.numberOfQuestions}}</p>
		<p>Corect answers: {{data.correctAnswers}}</p>
		<p>Score: {{data.correctAnswers/data.numberOfQuestions * 100 | number:'1.0-0'}} %</p>
		<hr> -->
			<div *ngIf="data.testPassed" class=" jdm-text-blue">
				<p class="status-icon">
					<img src="assets/Images/shared/done-icon.png">
				</p>
				<P>
					Congratulations! You successfully finished this section of the training. You can now continue to the
					next
					lesson.
				</P>
			</div>
			<div *ngIf="!data.testPassed" class="jdm-text-orange">
				<p class="status-icon">
					<img src="assets/Images/shared/close-icon.png">
				</p>
				<p>
					Please watch this video again, then take the test again. You need to pass this test in order to
					continue
					to
					the next lesson.
				</p>
			</div>
		</div>
	</div>
	<div mat-dialog-actions>
		<button *ngIf="data.testPassed" mat-button class="pull-right jdm-text-blue" mat-dialog-close (click)="okClick()"
			tabindex="-1">Continue</button>

		<button *ngIf="!data.testPassed" mat-button class="pull-right jdm-text-orange" mat-dialog-close
			(click)="resetTest()" tabindex="-1">Watch the video again</button>
	</div>
</div>