import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog'

@Component({
	selector: 'app-terms',
	templateUrl: './terms.component.html',
	styleUrls: ['./terms.component.scss']
})
export class TermsComponent implements OnInit {

	accepted;

	constructor(private termsDialogRef: MatDialogRef<TermsComponent>) { }

	userAcceptedTerms() {
		//update user terms value
		localStorage.setItem('JDM_DriverOrientation_TermsAndConditions', 'true');
		this.termsDialogRef.close();
	}

	ngOnInit() {
	}

}
