import { environment } from '../../environments/environment';

export const apiUrl = environment.apiUrl;
export const bridgeUrl = environment.bridgeUrl;
export const DS = '/';

export enum LocalVariable {
	Employee = 'jdmEmployeeLocalVariable',
};


// globals.ts
import { Injectable } from '@angular/core';

@Injectable()
export class Globals {
	role: string = 'test';
}




